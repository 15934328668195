.navbar-btn{
    padding: 18px;
    font-size: 16px;
    font-weight: 400;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

.navbar-pro-img{
    padding-top: 1px;
    padding-left: 1rem!important;
    margin-right: 1rem!important;
}

.navbar-bckgrd{
    background-color: #2B1A64;
}

.navbar-btn:hover{
    border-bottom-style: solid;
    border-bottom-color: #8CC93F;
    padding-bottom: 23px;
    border-bottom-width: medium;
    margin-bottom: -10px;
    position: relative;
    color: #8CC93F!important;
}

.navbar-btn-active{
    border-bottom-style: solid;
    border-bottom-color: #8CC93F;
    padding-bottom: 23px;
    border-bottom-width: medium;
    margin-bottom: -10px;
    position: relative;
    color: #8CC93F!important;
}

.navbar-btn-settings{
    padding: 0 !important;
    font-size: 16px;
    font-weight: 400;
    color: white!important;
}

.navbar-btn-settings:hover{
    color: #8CC93F!important;
}

.settings-active{
    color: #8CC93F!important;
}

.showDrop{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0 2px 4px 0, rgba(0, 0, 0, 0.08) 0 1px 2px 0;
    border-radius: 6px!important;
    margin-top: 8px;
    margin-left: -38px;
}
.info-box {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffea8a;
}
.email-ver{
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    border-radius: 6px;
    border-left: 3px solid #eec200 !important;
}
.action-card{
    padding-top: 25px;
    padding-bottom: 5px;
    cursor: pointer;
}
.action-card:hover{
    background-color: #fff9f2;
}
.action-card p{
    padding-top: 5px;
}
.action-card img{
    width: 20%;
}
.dropdown-text{
    font-size: 16px;
    padding-top: 10px;
    font-weight: 400;
    color: #332b64;
}
.dropdown-menu{
    left: 15px !important;
}
.navbar-brand{
    margin-right: 0 !important;
}
.drop-arrow{
    float: right;
    margin-right: 10px;
    position: relative;
    margin-top: -15px;
    color: white;
    transform: rotate(45deg);
}

.drop-icon{
    margin-right: 12px;
}

.desktop-hide{
    display: none;
}


/*mobile view*/
@media (max-width:767px) {

    .bars-icon:focus{
        outline: none;
    }

    .desktop-hide{
        display: block;
        padding-left: 4%!important;
    }

    .mobile-hide{
        display: none;
    }

    .showDrop {
        border: none!important;
        box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
        border-radius: 6px!important;
        margin-top: 8px;
        margin-left: -30px;
        width: 58.5%;
    }

    .action-card img{
        width: 40%;
    }
    .navbar-btn-active {
        border-bottom-style: solid;
        border-bottom-color: #8CC93F;
        padding-bottom: 14px;
        border-bottom-width: medium;
        margin-bottom: 8px;
        position: relative;
        color: #8CC93F!important;
        width: 40%;
    }
}