.your-transactions{
    padding-top: 0!important;
}

.your-transactions-txt{
    padding-left: 2.2%;
    padding-top: 2.2%;
    padding-bottom: 2.2%;
    margin-bottom: 0;
}

.phone-bckg{
    background-color: #ddddf3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 20%;
}

.reverse-bckg{
    height: 30px;
    padding: 3px;
    margin-top: 20%;
}

.phone-color{
    color: #2b1a64;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.tv-color{
    color: #ec1904;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.tv-bckg{
    background-color: #f7d5d2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 30%;
}

.wallet-color{
    color: #03e003;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.wallet-bckg{
    background-color: #dbf3db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 30%;
}

.table-dash-img{
    width: 8%;
    padding: 2.5%!important;
}

.trans-debit-color {
    color: #ff0018;
    padding-top: 19px;
}

.trans-credit-color {
    color: #1cdc48;
    padding-top: 19px;
}

.trans-date{
    margin-top: 18%;
    margin-bottom: 0!important;
}

.no-trans{
    width: 30%;
    padding: 9%;
    padding-bottom: 0!important;
    margin-top: 0.5%;
}

.no-trans-text{
    font-size: 24px;
    padding-bottom: 9%!important;
}

.spin-s{
    height: 30%!important;
    width: 30%!important;
    margin: 38%;
    opacity: 0.5;
}






/*mobile view*/
@media (max-width:767px) {

    .trans-debit-color {
        padding-top: 0!important;
    }

    .trans-credit-color {
        padding-top: 0!important;
    }

    .table-trans-details1 {
        margin-top: -1px!important;
    }

    .phone-bckg {
        margin-top: 33%;
    }

    .wallet-bckg {
        margin-top: 33%;
    }

    .tv-bckg {
        margin-top: 33%;
    }

    .no-trans{
        width: 40%;
        padding: 9%;
        padding-bottom: 0!important;
        margin-top: 8.5%;
    }

    .no-trans-text{
        font-size: 24px;
        padding-bottom: 25%!important;
    }

    .spin-s{
        height: 20%!important;
        width: 20%!important;
        margin: 38%!important;
        opacity: 0.5;
    }

}


.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #2b1a64;
  border-color: #2b1a64;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #2b1a64;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2b1a64;
  border-color: #2b1a64;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #2b1a64;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
