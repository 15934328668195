.navbar{
    box-shadow: 0 7px 20px 0 rgba(0,0,0,.04);
}
.header-text{
    margin-left:7px;
    margin-right: 7px;
    display: inline-block;
    position: relative;
    text-decoration: none;
}
.header-icon{
    color:#f59e25;
    widows: 42px;
    height: 42px;
    line-height: 42px;
    font-size:22px;
    display:inline-block;
    text-align: center;
    margin-right:  10px;
}
.text-wrap .icon-circle{
    line-height:1.2;
    padding:5px;
    color: black;
}

.list-unstyled > li > a {
    text-decoration: none;
    font-size: 18px;


}
.list-unstyled > li {
    padding-top: 10px;
}
.navbar-sidenav > li{
    background-color: #230a3c;
    color: white;
}
.navbar-sidenav > li > a{
    color: white;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: #230a3c;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    transition: 0.2s;
    box-shadow: rgba(0, 0, 0, 0.16) 0 0 8px 0, rgba(0, 0, 0, 0.2) -2px 0 8px 0;
}
.sidenav .closebtn {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: white;
}
.back-drop{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: none;
}
.sidenav .links{
    margin-top: 70px;
    padding: 20px;
}
.links a{
    color: white !important;
}

.loading-bar2{
    -webkit-animation:spin 0.5s linear infinite;
    -moz-animation:spin 0.5s linear infinite;
    animation:spin 0.5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
