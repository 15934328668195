.btn-top-up{
    padding: 3%;
    margin-top: 12%;
}

.btn-top-up-m{
    padding: 1%;
    min-width: -webkit-fill-available
}
.reference-img{
    
}