.bckgrd-color{
    background-color: #FBFAFF
}

.akawo-text-big-main{
    margin-top: -7%;
    font-weight: bold;
    margin-bottom: -14%;
}

.amt-invest-main {
    color: #8CC93F;
    margin-bottom: -3%;
}

.card-bal-invest {
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: bold;
}

.card-main-akawo{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    border-radius: 6px!important;
    padding: 2%;
}

.akawo-p{
    margin-top: 4%;
    margin-bottom: 4%;
    font-size: 14.5px;
}

.btn-withdraw{
    background-color: #2B1A64;
    padding: 14px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    border-style: none;
    width: 100%;
}

.btn-withdraw:focus{
    outline: none;
}

.btn-invest{
    background-color: #8CC93F;
}

.btn-invest:focus{
    background-color: #8CC93F;
}

.amt-akawo{
    margin-top: -5%;
    color: #2B1A64;
}

.akawo-card{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0 2px 4px 0, rgba(0, 0, 0, 0.08) 0 1px 2px 0;
    border-radius: 6px!important;
    padding: 3%;
}

.akawo-modal-close:focus{
    outline: none;
}

.akawo-amount-range-div{
    border: none!important;
    border-radius: 6px!important;
    padding: 3% 3% 0 3%;
}

.amt-style{
    margin-top: 10px;
    margin-bottom: 1%;
}

.akawo-table{
    margin-bottom: -5px;
    margin-top: 1px;
    font-weight: bold;
}

.akawo-nxt-btn{
    background-color: #2B1A64;
}

.btn-akawo-nxt{
    font-weight: 600;
    font-size: 100%;
    border-radius: 6px;
    background-color: #2B1A64;
    width: 53%;
    padding: 10px;
}

.btn-akawo-nxt:focus{
   outline: none
}

.akawo-card-wallet-pay {
    border-style: solid!important;
    border-width: 1.2px!important;
    border-color: transparent!important;
}

.akawo-card-card-pay {
    border-style: solid!important;
    border-width: 1.2px!important;
    border-color: transparent!important;
}

.akawo-card-wallet-pay:hover{
    border-style: solid!important;
    border-width: 1.2px!important;
    border-color: #de8e86!important;
}

.akawo-card-card-pay:hover{
    border-style: solid!important;
    border-width: 1.2px!important;
    border-color: #8CC93F!important;
}

.akawo-wallet-bckgrd{
    background-color: #f7d5d2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 3px;
}

.akawo-wallet-icon{
    color: #ec1904;
    font-size: 14px;
    line-height: 1.5rem;
}

.akawo-card-bckgrd{
    background-color: #dbf3db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 3px;
}

.akawo-card-icon{
    color: #03e003;
    font-size: 14px;
    line-height: 1.5rem;
}

.bankimg{
    width: 20%;
    margin-top: -5px;
    margin-right: 6px;
}

.nodisplay{
    display: none;
}

.card-inner-akawo-tran-text {
    padding-left: 2.2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.table-img-akawo {
    width: 9%;
}

.akawo-his-img{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    background-color: #ddddf3;
    margin-top: 10%;
}

.akawo-his-img-pos{
    padding-left: 2.2%!important;
}

.akawo-main-text-small {
    color: #a6a9ab;
    font-weight: bold;
    font-size: 13px;
}

.akawo-credit-color {
    color: #1cdc48;
    padding-top: 10px!important;
}

.akawo-debit-color {
    color: #ff0018;;
    padding-top: 10px!important;
}

.akawo-date{
    margin-top: 5%;
}

.no-akawo-trans {
    width: 27%;
    padding: 11%;
    padding-bottom: 0!important;
    padding-top: 2%;
}







/*mobile view*/
@media (max-width:767px) {

    .akawo-text-big-main {
        margin-top: -7%;
        font-weight: bold;
        margin-bottom: 5%;
    }

    .akawo-main-text-small {
        color: #a6a9ab;
        font-weight: bold;
        font-size: 13px;
        padding-top: 8%;
        margin-bottom: 0px;
    }

    .amt-invest-main {
        color: #8CC93F;
        margin-bottom: 3%;
        margin-top: 3%;
    }

    .akawo-p {
        margin-top: 5%;
        margin-bottom: 10%;
        font-size: 14.5px;
    }

    .btn-withdraw {
        padding: 14px;
        font-size: 20px;
        border-radius: 6px;
        font-weight: 500;
        cursor: pointer;
        border-style: none;
        width: 39%;
        margin-left: 2%;
        margin-right: 3%;
        margin-bottom: 1%;
    }

    .akawo-his-img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        background-color: #ddddf3;
        margin-top: 28%;
    }

    .akawo-date{
        margin-top: 10%;
    }

    .td-width{
        width: 22%;
    }

    .akawo-credit-color {
        color: #1cdc48;
        padding-top: 3px!important;
    }

    .akawo-debit-color {
        color: #ff0018;;
        padding-top: 3px!important;
    }

    .card-inner-akawo-tran-text {
        padding-left: 2.2%;
        padding-top: 3.5%;
        padding-bottom: 3.5%;
    }

    .card-mag-b{
        margin-top: 4.8%;
    }

    .amt-akawo {
        margin-top: -12%;
        margin-bottom: 10px;
        color: #2B1A64;
    }

    .m-payment-text{
        margin-left: -20%;
    }

    .akawo-wal-pad{
        padding-left: 3px;
    }

    .akawo-dash-details1 {
        margin-top: 0px!important;
    }

    .withdraw-btn-m{
        width: 44%!important;
        padding: 12px!important;
    }
}