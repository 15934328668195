.bckgrd-color{
    background-color: #FBFAFF
}

.dashbord-con{
    padding-top: 1.8%;
    padding-bottom: 1.8%;
}

.card-balance{
    margin-bottom: 10%;
}

.card-inner-pad{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    border-radius: 6px!important;
    /*background-color: #efeaff;*/
}

.card-inner-pad-tran{
    padding-top: 1.6%;
    padding-bottom: 0!important;
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    border-radius: 6px!important;
}

.card-inner-pad-tran-text{
    padding-left: 2.2%;
    padding-top: 0.8%;
    padding-bottom: 0.8%;
}
.light-blue-bckg{
    background-color: #2B1A64;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;border-bottom: 2px solid #8CC93F;
}
.btn-success-outline{
    border-color: #8CC93F;
    border-width: 0.15rem;
}

.table-hover:hover{
    background-color: #FBFAFF!important;
}

.table-dash{
    width: 12%;
}

.table-dash2{
    width: 12%;
}

.trans-date-dash{
    padding-top: 8%;
}

.table-img-dash{
    width: 9%;
    padding: 2.5%!important;
}

.table td, .table th {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.table-dash-details1{
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 6px;
}

.table-dash-details2{
    font-size: 12px;
    margin-top: -25px;
    margin-bottom: 0;
}

.table-dash-details3{
    font-size: 15px;
    margin-top: -30px;
    margin-bottom: 0;
}

.debit-color{
    color: #ff0018;
    padding-top: 10px;
}

.credit-color{
    color: #1cdc48;
    padding-top: 10px;
}

.btn-fund{
    background-color: #8CC93F;
    padding: 10px;
    font-size: 18px;
    border-radius: 6px;
    cursor: pointer;
    border-style: none;
    width: 200px;
}

.btn-fund:focus{
    outline: none;
}

.fund-img{
    width: 22px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.loan-img{
    width: 35px;
    margin-bottom: 2px;
    margin-right: -3px;
}

.btn-loan{
    background-color: #8CC93F;
    padding: 8px;
    font-size: 13px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    border-style: none;
    width: 45%;
}

.btn-loan:focus{
    outline: none;
}

.card-bal{
    padding-top: 5%;
    padding-bottom: 4.8%;
    font-weight: bold;
}

.card-bal-your{
    padding-bottom: 1%;
    font-weight: bold;
}

.akawo-text-small{
    color: #a6a9ab;
    font-weight: bold;
    font-size: 13px;
    padding-top: 30%;
}

.akawo-text-big{
    margin-top: -27%;
    font-weight: bold;
}

.amt-invest{
    color:  #8CC93F;
}

.card-bal-header{
    padding-top: 7%;
}

.card-akawo-header{
    padding-top: 9%;
    font-weight: bold;
    color: #a6a9ab!important;
}

.card-bill{
    margin-top: 2.8%;
    padding-bottom: 0!important;
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0 2px 4px 0, rgba(0, 0, 0, 0.08) 0 1px 2px 0;
    border-radius: 6px!important;
}

.bills-hr{
    margin-top: -16px;
    margin-bottom: 0;
}

.bills-img-col{
    flex: 0 0 16.666667%;
    max-width: 10.666667%;
    margin-top: 2.8%;
    margin-bottom: 1.8%;
}

.bills-img-elec-col{
    flex: 0 0 16.666667%;
    max-width: 13.666667%;
    margin-top: 1.8%;
}

.phed{
    width: 128%;
    margin-top: -10px;
}

.ekdc{
    width: 78%;
    margin-top: 4px;
    margin-left: 42%;
}

.iedc{
    width: 85%;
    margin-top: -1px;
}

.phone-bckg-dash{
    background-color: #ddddf3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 3px;
}

.phone-color-dash{
    color: #2b1a64;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.tv-color-dash{
    color: #ec1904;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.tv-bckg-dash{
    background-color: #f7d5d2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 3px;

}

.wallet-color-dash{
    color: #03e003;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.wallet-bckg-dash{
    background-color: #dbf3db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    margin-top: 3px;
}

.loan-landing-img{
    width: 100%;
    margin-top: 2%;
    border-radius: 6px;
}

.no-data > img {
    width: 100px;
    opacity: 0.6;
}

.no-data > p {
    font-size: 16px;
}
.no-data.full-width-data{
    padding:10%;
}
.no-data{
    padding:10%;
}
/*mobile view*/
@media (max-width:767px) {

    .card-balance {
        margin-bottom: 6%!important;
        margin-top: -5%!important;
    }

    .card-inner-pad {
        padding: 5%;
        border: none!important;
        box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
        border-radius: 6px!important;
        /*background-color: #efeaff;*/
        margin-bottom: 7%;
    }

    .akawo-text-big {
        font-weight: bold;
        margin-top: 0!important;
    }

    .card-akawo-header {
        padding-top: 5%;
        font-weight: bold;
        color: #a6a9ab!important;
        margin-bottom: -1px;
    }

    .akawo-text-small {
        color: #a6a9ab;
        font-weight: bold;
        font-size: 13px;
        padding-top: 8%;
        margin-bottom: 5px;
    }

    .amt-invest {
        color: #8CC93F;
        margin-bottom: 3px!important;
    }

    .phone-bckg-dash {
        background-color: #ddddf3;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 10px;
    }

    .tv-bckg-dash {
        background-color: #f7d5d2;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 10px;
    }

    .wallet-bckg-dash {
        background-color: #dbf3db;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 10px;
    }

    .table-dash {
        width: 22%!important;
        text-align: center;
    }

    .trans-date-dash {
        padding-top: 26%;
    }

    .table-dash-details1 {
        font-size: 14px;
        margin-top: 9px;
        margin-bottom: 1px;
    }

    .row-m{
        margin-bottom: 16px!important;
    }

    .round-btn-m{
        padding: 0;
    }

    .depo-m{
        padding-top: 15px!important;
    }

    .depo-img-m{
        margin-top: -5%;
    }

    .table td, .table th {
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }

    .amount-td{
        padding-left: 0!important;
        width: 30%!important;
        padding-right: 3%;
    }

    .ver-m-view{
        margin-top: 4%!important;
    }

    .pad-top-m{
        padding-top: 5%;
    }
}

.round-btn{
    border-radius: 50%;
    height: 50px;
    background-color: #f4f2fa;
    padding: 20px;
    padding-right: 15px;
    padding-left: 15px;
}
.round-btn i{
    font-size: 20px;
    color: #2B1A64 !important;
}
.wallet-status{
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 10px;
    background-color: rgba(200, 255, 179, 0.27);
    color: #64df44;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.img-dash{
    width: 35%;
}
/* account_number_section  */
.account-container{
    display:  flex;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    padding: 0.8rem;
    margin-bottom: 1rem;
    gap: 0.5rem;
    text-decoration: none;
    color: #000;
    border-radius: 5px;
}

.account-link{
    text-decoration: none !important;
}