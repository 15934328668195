.pro-image-upload{
    position: relative;
    margin-left: 0.5%;
    font-weight: 600;
    cursor: pointer;
    color: #8CC93F;
}

.profile-nav{
    border-bottom-style: solid;
    border-bottom-color: transparent;
    border-bottom-width: medium;
    box-shadow: rgba(0,0,0,0.04) 0 2px 4px 0, rgba(0, 0, 0, 0.08) 0 1px 2px 0;
    border-radius: 4px!important;
    margin-bottom: 5%;
    padding: 15px;
    color: #2B1A64;
    background-color: white;
    /*font-weight: 600;*/
}

.profile-nav:hover{
    border-bottom-style: solid;
    border-bottom-color: #8CC93F;
    border-bottom-width: medium;
    position: relative;
    color: #8CC93F!important;
}

.nav-link.active, .nav-pills .show>.nav-link {
    border-bottom-style: solid;
    border-bottom-color: #8CC93F;
    border-bottom-width: medium;
    position: relative;
    color: #8CC93F!important;
}

.backgrd-color.active{
    background-color: white!important;
}

.profile-input-label{
    font-size: 13px;
}

.btn-profile-update {
    font-weight: 600;
    font-size: 100%;
    border-radius: 6px;
    background-color: #2B1A64;
    width: 20%;
    padding: 10px;
    float: right;
    border-style: none;
}

.btn-profile-update:focus{
    outline: none;
}

.akawo-bank-input {
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    width: 100%;
    margin-bottom: 3%;
    padding: 1.3%;
    border-radius: 6px;
}

.akawo-bank-input:focus {
    outline: none;
}

.btn-profile-add-bank {
    font-weight: 600;
    font-size: 100%;
    border-radius: 6px;
    background-color: #E8913A;
    width: 26%;
    padding: 10px;
    margin-bottom: 3%;
    border-style: none;
}

.btn-profile-add-bank:focus{
    outline: none;
}

.times-bckg {
    background-color: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    text-align: center;
}

.times-color {
    color: transparent;
    padding-bottom: 7px;
    font-size: 1rem;
}

.show-times{
    background-color: #f5e2e0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    text-align: center;
    cursor: pointer;
}

.show-times-color {
    color: #de8e86;
    padding-top: 4px;
    font-size: 1rem;
}
.table td, .table th{
    padding: 1em;
}
.btn-add-bank-click {
    font-weight: 600;
    font-size: 100%;
    border-radius: 6px;
    background-color: #2B1A64;
    width: 37%;
    padding: 10px;
    float: right;
    margin-bottom: 6%;
    border-style: none;
}

.btn-add-bank-click:focus {
    outline: none;
}

.img-settings{
    margin-bottom: 5%;
}

.form-control-pad{
    padding: 0.9rem .75rem!important;
    height: auto;
}





/*mobile view*/
@media (max-width:767px) {

    .card-settings {
        padding: 8%!important;
    }

    .img-settings{
        margin-bottom: 10%;
        margin-top: 10%;
    }

    .btn-profile-update {
        width: 37%!important;
    }

    .selected-bank{
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .add-bank-text{
        margin-top: 10%;
        margin-bottom: 10%;
        text-align: center;
    }

    .btn-profile-add-bank {
        width: 63%!important;
        margin-bottom: 8%!important;
    }

    .times-color {
        color: #de8e86;
        padding-bottom: 0!important;
        font-size: 1rem;
        padding-top: 4px!important;
    }

    .times-bckg{
        background-color: #f5e2e0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        text-align: center;
        cursor: pointer;
        margin-top: 28%;
        float: right;
    }

    .p-bank{
        font-size: 13px;
        margin-bottom: 0px;
    }

    .bank-list{
        border-bottom-style: groove;
        border-width: 2.5px;
        padding-bottom: 7px;
        padding-top: 10px;
    }

    .modal-m-pad{
        margin-top: 27%!important;
    }
}