.loan-text{
    margin-bottom: 6px;
    font-size: 14px;
    margin-top: -25px;
}

.loan-text2{
    margin-bottom: 6px;
    font-size: 14px;
}

.loan-date{
    padding-top: 14%;
}

.loan-amt-pad{
    padding-right: 1.8%!important;
}

.loan-img-text{
    position: absolute;
    margin-left: -11px;
    font-size: 13px;
    margin-top: -2px;
}

.loan-img-text-due{
    margin-left: 1px;
}

.loan-img-text-repaid{
    margin-left: -7px;
}

.loan-bckg-due {
    background-color: #f5e2e0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.loan-bckg-pending {
    background-color: #f7f7fb;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.loan-bckg-repaid {
    background-color: #e3f1e3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.loan-img-due {
    color: #de8e86;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.loan-img-pending {
    color: #b4b4dc;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.loan-img-repaid {
    color: #81d481;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.loan-credit-color {
    color: #1cdc48;
}

.plus-color{
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
    padding-left: 0.35rem;
}

.minus-color{
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
}

.loan-img-bckg {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    background-color:#8CC93F;
    margin-top: 10%;
    cursor: pointer;
}

.loan-days {
    background-color: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 3px;
    margin-top: 10%;
}








/*mobile view*/
@media (max-width:767px) {

    .loan-bckg-pending {
        background-color: #f7f7fb;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 9px;
    }

    .loan-date {
        padding-top: 40%;
    }

    .loan-text2 {
        margin-bottom: 2px;
        font-size: 14px;
        margin-top: 2%;
    }

    .loan-amt-pad {
        padding-right: 4%!important;
        width: 34%;
    }

    .loan-bckg-due {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 9px;
    }

    .loan-bckg-repaid {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 3px;
        margin-top: 20px;
        margin-left: 9px;
    }

    .loan-text {
        margin-bottom: 5px;
        font-size: 14px;
        margin-top: -25px;
    }

    .your-transactions-txt {
        padding-left: 2.2%;
        padding-top: 4.2%!important;
        padding-bottom: 4.2%!important;
        margin-bottom: 0;
    }

    .dashbord-con {
        padding-top: 6.8%!important;
    }
}
.loan-card{
    box-shadow: 0 1px 3px rgba(0,0,0,.15);
    border-radius: 8px;
}