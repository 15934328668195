.btn-trans-bank{
    margin-left: auto;
    margin-right: 20%;
}

.btn-trans-ht{
    margin-left: 20%;
}

.btn-trans-bank-p{
    margin-left: auto;
    text-align: center;
    color: #2b1a64;
}

.my.active{
    background-color: transparent!important
}

.btn-trans-ht-p{
    text-align: center;
    color: #2b1a64;
}

.card-trans{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px;
    border-radius: 6px!important;
    padding: 5%;
}



/*mobile view*/
@media (max-width:767px) {

    .btn-trans-bank{
        margin-left: auto;
        margin-right: 10%;
    }


    .btn-trans-ht{
        margin-left: 10%;
    }


}