.airtime-card{
    padding: 10px 10px 5px;
    width: 100%!important;
    opacity: .5;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all .5s;
    border: 2px solid #efefef;
}
.selected>.airtime-card{
    opacity: 1;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.text-blue {
    color: #004fc4 !important;
}

.backgrd-white{
    background-color: white!important;
}

.cursor-pointer{
    cursor: pointer;
}

.cable-active{
    border-color: #75bee0!important;
    opacity: 1;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.hide{
    display: none;
}
.show{
    display: block;
}