.login-section{
/*/margin-top:5% !important;/*/
/* #F7F8FF */
}
a{
    color: #0000ba;
}
.all-card{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    padding: 10px;
    border: 0;
}
.login-card{
    border-radius: 5px;
    padding:30px;
    padding-top: 50px;
    padding-bottom: 50px;
    border: none;
}
.mb-3{
    border-radius: 50%;
    display: inline-flex !important;
    width: 25%;
}
.customer-info{
    font-weight: 700;
    padding-bottom: 65px;
}
.head-text{
    font-size: 45px;
    font-weight: bold;
}
.text1{
    font-size: 40px;
    font-weight: bold;
}
.home-section{
    font-family: "Roboto Slab";
   
}
.section-2-header{
    font-size: 36px;
    font-weight: 600;
    line-height: 
    50.4px;
}
.head-para{
    font-size: 18px;
    font-weight: bold;
}
.login-para{
    font-size: 14px;
    font-weight: bold;
}

.phone-img{
    margin-top: -90px;
    margin-bottom: -40px;
}
.fifth1-section{
    position: absolute;
    opacity: 0.1;
}

.first-section{
}

.text-blue{
    color: #2C2948;}

.second-section{
    background-color: #230a3c;
/*/background-image: url(./Path.png);/*/
background-repeat: no-repeat;
    position: relative;
    z-index: 12;
}
.fourth-section{
    background-color: #FBFBFB;
    height: 610px;

}

.fourth1-section{
    width: 30%;
    position: absolute;
    z-index: 14;
    margin-left: 877px;
    margin-top: 30px;
    border-radius: 5px;
}
.fourth2-section{
    background-image: url(./Rectangle.png);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 12;
}

.loading-bar{
    text-align: -webkit-center;
    padding-top: 140px;
}
.faqs-section{
    padding-top: 64px !important;
}
.faq-right-img{
    width: 20%;
    margin-top: 90px;
}
.faq-left-img{
    width:20%;
}
.faq-dots-icon{
    position: absolute;
    width: 40%;
    margin-left: 475px;
}
.faq-img{
    position: absolute;
    padding-right: 45px;
    margin-left: -100px;
}
.faq-card{
    border-bottom: 1px solid rgb(235, 235, 235);

}
.faq-card > div > button{
    padding: 20px 90px;
    font-size: 16px;
    color: black !important;
    text-decoration: none !important;
}
.faq-card-header{
    background-color:none;
}
.faq-card-body{
    background-color:#FCFCFC;
    padding: 20px 90px;
}
.quote-img{
    padding-top: 80px;
    width: 91px;
    margin: 0 auto 50px 0;
}
.third-section{
    background-color: #F7F8FF;

}
section{
    padding-top: 40px;
    padding-bottom: 10px;
}
.google-btn{
    width: 100%;
    cursor: pointer;
}
.btn-outline-primary{
    border: 1px solid #411e3f;
    color: #212529;
}
.btn-outline-primary:hover{
    background-color: #411e3f;
    color:#f8f8f8 !important;
    border: 1px solid #411e3f;
}
.btn-outline-primary:active{
    background-color: #411e3f !important;
}
.btn{
    padding: 10px 20px;
    font-size: 14px;
    min-width: 111px;
    line-height: 25px;
    border-radius: 3px;
    cursor: pointer;
}
.btn-adj{
    padding: 10px 14px;
}
.signup-btn{
    background-color: #411e3f;
}
.react-code-input > input{
    box-shadow: none !important;
    width: 40px !important;
    margin: 0.2rem !important;
    height: 40px !important;
    font-size: 22px !important;
    text-align: center !important;
    padding-left: 0 !important;

}
.react-code-input > input:focus{
    border-color: #495057 !important;
    outline: 0;
}
li{
    cursor: pointer;
    list-style: none;
    font-size: 14px;
}
li > a{
    color:black;
}
li > a:hover{
    color: #411e3f;
    text-decoration:none !important;
}
.btn-success{
    background-color: #8CC93F;
    border-color: #8CC93F;
}
.btn-success:hover{
    background-color: #7eb639;
    border-color: #7eb639;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-control:focus{
    box-shadow: none;
    border-color: #495057;
}
.form-control{
    border: 1px solid #eee;
    padding: 1.6rem .75rem;
}
.form-control::placeholder{
    color: #bfbfbf;
    font-size:14px;
}
.pic-1-sec3{
    width: 37%;
}
.pic-2-sec3{
    width: 37%;
    padding: 14px;
}
.pic-3-sec3{
    width: 37%;
    padding: 21px;
}
.no-decoration{
    color: black;
}
.no-decoration:hover{
    text-decoration: none !important;
}
.btn-rad{
    border-radius: 30%;
}
.first-footer-div{
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(226, 210, 225);
    border-top: 2px solid green;
}
.second-footer-div{
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(226, 210, 225);
}
.footer-logo{
    width: 50%;
    float: right;
}
.list-group-item{
    border: none;
}
.login-section{
    background-color: #F7F7F7;
}
.login-text{
    font-weight: bold;
    padding-bottom: 40px;
}
.footer-li{
    display: inline-flex;
}
.icon-circle{
    border-radius: 50%;
    background-color: #411e3f;
    padding: 6px;
}
.contact-icon{
    padding: 10px;
    width: 50px;
}
.contact-section{
    margin-top: 20px;
    margin-bottom: 120px;
}
.footer-section{
    padding-top: 0;
    padding-bottom: 20px;
}
.about-section{
    background-image: url(./dots.png);
}
.text-box {
    border: 15px solid #411e3f;
    padding: 100px;
    margin: 50px;
}
.about-blockquote{
    position: absolute;
    background: #411e3f;
    padding: 60px;
    margin-left: 70px;
    margin-top: 35px;

}
.about-icon{
    width: 75px;
}
.blockquote-size{
    font-size: 20px;
    display: flex;
}
.testimonial-img{
    padding: 80px !important;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .quote-img{
        padding-top: 90px;
        width: 91px;
        margin: 30px auto 30px auto;
    }
    .head-text{
        font-size: 30px;
        font-weight: bold;
    }
    .head-para{
        font-size: 14px;
        font-weight: bold;
    }
    
    .fourth1-section{
        display: none;

    }
    .fifth-section {
        padding-top: 1px;
    }
    .footer-li{
        display: block;
    }
    .logo{
        width: 70% !important;
    }
    .google-btn{
        width: 97%;
    }
    .customer-section{
        padding: 0 !important;
    }
    .quote-img{
        padding-top: 0;
    }
    .faq-left-img {
        width: 50% !important;
    }
    .faq-right-img {
        width: 50% !important;
        margin-top: 90px !important;
    }
    .faq-dots-icon {
        position: absolute !important;
        width: 40% !important;
        margin-left: 0;
    }
    .invest-img{
        width: 100% !important;
    }
    .testimonial-img{
        padding: 0 !important;
    }
    .faq-img{
        padding: -32px !important;
        width: 100% !important;
        margin-top: -340px !important;
        position: relative;
        padding-right: 0;
        margin-left: 0;
    }
    .faqs-section{
        padding-top: 70px !important;
        padding-bottom: 0px !important;
    }
    .faq-card > div > button {
        padding: 0px 0;
        font-size: 10px;
    }
    .faq-card-body {
        padding: 1.25rem;
    }
    .pay{
        padding-top: 20px;
    }
    .text-box {
        border: 15px solid #411e3f;
        padding: 15px;
        margin: 0;
    }
    .about-blockquote {
        position: relative;
        background: #411e3f;
        padding: 0;
        margin-left: 0;
        margin-top: 0;
    }
    .blockquote-size {
        font-size: 11px;
        padding: 10px;
        text-align: center;
    }
    .abt-img{
        width: 100%;
    }
}